import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import "./Work.scss";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";

export interface WorkItem {
  name: string;
  title: string;
  description: string;
  projectLink: string;
  codeLink: string;
  imgUrl: string;
  date: string;
  tags: string[];
}

const Work = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState<WorkItem[]>([]);
  const [filterWork, setFilterWork] = useState<WorkItem[]>([]);

  useEffect(() => {
    const query = '*[_type=="works"] | order(date desc)';
    client.fetch(query).then((data: WorkItem[]) => {
      const sortedWork = data.sort(
        (a: WorkItem, b: WorkItem) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setWorks(sortedWork);
      setFilterWork(sortedWork);
    });
  }, []);

  const handleWorkFilter = (item: string) => {
    const lowerCaseItem = item.toLowerCase(); // Convert item to lowercase
    setActiveFilter(item);
    setAnimateCard({ y: 100, opacity: 0 });

    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });

      if (lowerCaseItem === "all") {
        setFilterWork(works);
      } else {
        setFilterWork(
          works.filter((work) =>
            work.tags.some((tag) => tag.toLowerCase() === lowerCaseItem)
          )
        );
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text" style={{ marginTop: 15 }}>
        My creative <span>Portfolio</span>
      </h2>
      <div className="app__work-filter">
        {[
          "Web",
          "App",
          "BlockChain",
          "AR/VR",
          "Gen AI/ML",
          "UI/UX",
          "Cloud",
          "All",
        ].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img src={urlFor(work.imgUrl).url()} alt={work.name} />
              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex"
              >
                {work.projectLink && (
                  <a href={work.projectLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillEye />
                    </motion.div>
                  </a>
                )}

                {work.codeLink && (
                  <a href={work.codeLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillGithub />
                    </motion.div>
                  </a>
                )}
              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className="app__work-tag app__flex">
                <p className="p-text">{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg'"
);
