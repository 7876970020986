import React, { useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import images from "../../constants/images";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const animatedRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (animatedRef.current) {
        const node = animatedRef.current as HTMLElement;
        if (!node.contains(event.target as Node)) {
          setToggle(false); // Close the menu when clicked outside
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href="#home">
          <img src={images.logo} alt="logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        {[
          "home",
          "about",
          "work",
          "experience", // Change from "skills" to "experience"
          "achievement",
          "testimonials",
          "contact",
        ].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item === "experience" ? "skills" : item}`}>
              {item === "experience" ? "Experience" : item}
            </a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        <motion.div
          ref={animatedRef}
          initial={{ x: 300 }}
          animate={toggle ? { x: 0 } : { x: 300 }}
          transition={{ duration: 0.85, ease: "easeOut" }}
        >
          {toggle && (
            <>
              <HiX onClick={() => setToggle(false)} />
              <ul className="app__navbar-links">
                {[
                  "home",
                  "about",
                  "work",
                  "experience", // Change from "skills" to "experience"
                  "achievement",
                  "testimonials",
                  "contact",
                ].map((item) => (
                  <li key={item}>
                    <a
                      href={`#${item === "experience" ? "skills" : item}`}
                      onClick={() => setToggle(false)}
                    >
                      {item === "experience" ? "Experience" : item}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </motion.div>
      </div>
    </nav>
  );
};

export default Navbar;
