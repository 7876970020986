import React from "react";
import { SocialMedia, NavigationDots } from "../components";
import "../App.scss";
type ComponentType = React.ComponentType<any>;
const AppWrap = (
  Component: ComponentType,
  idName: string,
  classNames: string
) =>
  function HOC() {
    return (
      <div id={idName} className={`app__container ${classNames}`}>
        <SocialMedia />

        <div className="app__wrapper app__flex">
          <Component />

          <div className="copyright">
            <p className="p-text">@2023 subho004</p>
            <p className="p-text">All rights reserved</p>
          </div>
        </div>
        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;
