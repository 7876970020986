import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";

export interface Skill {
  name: string;
  bgColor: string;
  icon: string;
  rank: number;
}
export interface WorkDetail {
  desc: string;
}
export interface extraDocument {
  certificate: string;
}
export interface Experience {
  name: string;
  bgColor: string;
  logo: string;
  website: string;
  position: string;
  workType: string;
  location: string;
  startDate: string;
  endDate: string;
  description: WorkDetail[];
  certificate: extraDocument[];
}

const Skills = () => {
  const [experience, setExperience] = useState<Experience[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [showAllExperiences, setShowAllExperiences] = useState(false);
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [expandedExperiences, setExpandedExperiences] = useState<number[]>([]);
  const experiencesToShowInitially = 2;
  const skillsToShowInitially = 20;
  const maxDescriptionWords = 50;

  const toggleExperiences = () => {
    setShowAllExperiences(!showAllExperiences);
  };
  const toggleSkills = () => {
    setShowAllSkills(!showAllSkills);
  };
  const toggleDescription = (index: number) => {
    const updatedExpandedExperiences = [...expandedExperiences];
    const experienceIndex = updatedExpandedExperiences.indexOf(index);

    if (experienceIndex === -1) {
      updatedExpandedExperiences.push(index);
    } else {
      updatedExpandedExperiences.splice(experienceIndex, 1);
    }

    setExpandedExperiences(updatedExpandedExperiences);
  };

  const totalWordsInDescription = experience.reduce((total, exp) => {
    return (
      total +
      (exp.description?.reduce((count, work) => {
        return count + work.desc.split(" ").length;
      }, 0) || 0)
    );
  }, 0);
  const getButtonText = (expIndex: number) => {
    return expandedExperiences.includes(expIndex)
      ? "Show Less..."
      : "Read More...";
  };

  useEffect(() => {
    const query = '*[_type=="experience"] | order(startDate desc)';
    const skillsQuery = '*[_type=="skills"] | order(rank desc)';
    client.fetch(query).then((data: Experience[]) => {
      const sortedExperiences = data.sort(
        (a: Experience, b: Experience) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      );
      setExperience(sortedExperiences);
    });
    client.fetch(skillsQuery).then((data: Skill[]) => {
      const sortedSkills = data.sort((a: Skill, b: Skill) => b.rank - a.rank);
      setSkills(sortedSkills);
    });
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "MMMM yyyy");
  };

  const computeDuration = (startDate: string, endDate: string): string => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();

    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30.44));

    const years = Math.floor(diffMonths / 12);
    const months = diffMonths % 12;

    if (years > 0 && months > 0) {
      return `${years} yr ${months} mos`;
    } else if (years > 0) {
      return `${years} yr`;
    } else {
      return `${months} mos`;
    }
  };
  const parseText = (text: string) => {
    const boldRegex = /\*(.*?)\*/g;
    const boldText = text.replace(boldRegex, "<strong>$1</strong>");
    return <span dangerouslySetInnerHTML={{ __html: boldText }} />;
  };

  return (
    <>
      <h2 className="head-text" style={{ marginTop: 15 }}>
        Skills & Experiences
      </h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills
            .slice(0, showAllSkills ? skills.length : skillsToShowInitially)
            .map((skill, index) => (
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__skills-item app__flex"
                key={index}
              >
                <div
                  className="app__flex"
                  style={{ backgroundColor: skill.bgColor }}
                >
                  <img
                    src={urlFor(skill.icon).url()}
                    alt={`Icon for ${skill.name}`}
                  />
                </div>
                <p className="p-text">{skill.name}</p>
              </motion.div>
            ))}
          {!showAllSkills && skills.length > skillsToShowInitially && (
            <p className="read-more-text app__flex" onClick={toggleSkills}>
              Show More Skills...
            </p>
          )}
        </motion.div>
        <motion.div className="app__skills-exp">
          {experience
            .slice(
              0,
              showAllExperiences
                ? experience.length
                : experiencesToShowInitially
            )
            .map((exp, expIndex) => (
              <motion.div className="app__skills-exp-item" key={expIndex}>
                <div className="app__skills-exp-year">
                  <a href={exp.website} target="_blank" rel="noreferrer">
                    <img
                      src={urlFor(exp.logo).url()}
                      alt={`Logo of ${exp.name}`}
                      className="circular-image"
                    />
                  </a>
                </div>

                <motion.div className="app__skills-exp-works">
                  <h3 className="bold-text">{exp.position}</h3>
                  <div className="company-info">
                    <p className="p-text">{exp.name}</p>
                    <span className="separator-dot">. </span>
                    <p className="p-text">{exp.workType}</p>
                  </div>

                  <div className="company-info">
                    <p className="p-time">
                      {formatDate(exp.startDate)} -{" "}
                      {exp.endDate ? formatDate(exp.endDate) : "Present"}
                    </p>
                    <span className="separator-dot">. </span>
                    <p className="p-time">
                      {computeDuration(exp.startDate, exp.endDate)}
                    </p>
                  </div>
                  <p className="p-time">{exp.location}</p>

                  <div>
                    <div>
                      <div>
                        {totalWordsInDescription > maxDescriptionWords ? (
                          <>
                            {exp.description &&
                              exp.description.map(
                                (work, index) =>
                                  (index < 2 ||
                                    expandedExperiences.includes(expIndex)) && (
                                    <h5 className="custom-h5" key={index}>
                                      {parseText(work.desc)}
                                    </h5>
                                  )
                              )}
                            <div className="certificates-container">
                              {exp.certificate &&
                                exp.certificate.length > 0 &&
                                exp.certificate.map((cert, index) => (
                                  <a
                                    key={index}
                                    href={urlFor(cert.certificate).url()}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div className="image-container">
                                      <img
                                        src={urlFor(cert.certificate).url()}
                                        alt={`Certificate ${index}`}
                                      />
                                    </div>
                                  </a>
                                ))}
                            </div>
                            <p
                              className="read-more-text"
                              onClick={() => toggleDescription(expIndex)}
                            >
                              {getButtonText(expIndex)}
                            </p>
                          </>
                        ) : (
                          <>
                            {exp.description &&
                              exp.description.map((work, index) => (
                                <h5 className="custom-h5" key={index}>
                                  {parseText(work.desc)}
                                </h5>
                              ))}
                            <div className="certificates-container">
                              {exp.certificate &&
                                exp.certificate.length > 0 &&
                                exp.certificate.map((cert, index) => (
                                  <a
                                    key={index}
                                    href={urlFor(cert.certificate).url()}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div className="image-container">
                                      <img
                                        src={urlFor(cert.certificate).url()}
                                        alt={`Certificate ${index}`}
                                      />
                                    </div>
                                  </a>
                                ))}
                            </div>
                            {exp.description && exp.description.length > 2 && (
                              <p
                                className="read-more-text"
                                onClick={() => toggleDescription(expIndex)}
                              >
                                Show Less...
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            ))}

          {!showAllExperiences &&
            experience.length > experiencesToShowInitially && (
              <p className="read-more-text" onClick={toggleExperiences}>
                Read More...
              </p>
            )}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
