import React, { useState, useEffect } from "react";
import "./About.scss";
import { motion } from "framer-motion";
import { client, urlFor } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";

interface AboutData {
  title: string;
  imgUrl: string;
  description: string;
  rank: Number;
}
const About = () => {
  const [abouts, setAbouts] = useState<AboutData[]>([]);

  useEffect(() => {
    const query = '*[_type=="abouts"] | order(rank desc)';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
    client.fetch(query).then((data: AboutData[]) => {
      const sortedAbout = data.sort((a: AboutData, b: AboutData) => {
        const rankA = Number(a.rank);
        const rankB = Number(b.rank);
        return rankB - rankA;
      });
      setAbouts(sortedAbout);
    });
  }, []);
  return (
    <>
      <h2 className="head-text" style={{ marginTop: 15 }}>
        I know that <span>Good Developer</span>
        <br />
        means <span>Good Problem Solver</span>
      </h2>
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            <div style={{ backgroundColor: "white" }}>
              <img
                src={urlFor(about.imgUrl).url()}
                alt={about.title}
                style={{ backgroundColor: "white" }}
              />
            </div>
            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {about.title}
            </h2>
            <p className="p-text" style={{ marginTop: 10 }}>
              {about.description}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
