import React from "react";
import { FaGithub, FaLinkedin, FaCode } from "react-icons/fa";
import "./SocialMedia.scss";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a
        href="https://leetcode.com/subho004"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon-link"
      >
        <div className="social-icon">
          <FaCode size={24} />
        </div>
      </a>
      <a
        href="https://github.com/subho004"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon-link"
      >
        <div className="social-icon">
          <FaGithub size={24} />
        </div>
      </a>
      <a
        href="http://www.linkedin.com/in/subho004"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon-link"
      >
        <div className="social-icon">
          <FaLinkedin size={24} />
        </div>
      </a>
    </div>
  );
};

export default SocialMedia;
