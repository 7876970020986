import imageUrlBuilder from "@sanity/image-url";
import { createClient, type ClientConfig } from "@sanity/client";

const config: ClientConfig = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: "production",
  apiVersion: "2023-11-26",
  useCdn: true,
  token: process.env.REACT_APP_SANITY_TOKEN,
};
export const client = createClient(config);

const builder = imageUrlBuilder(client);

export const urlFor = (source: string) => builder.image(source);
