import React, { useState, useEffect } from "react";
import "./Testimonial.scss";
import { motion } from "framer-motion";

import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";

export interface Testimonials {
  name: string;
  company: string;
  imgUrl: string;
  feedback: string;
  rank: number;
}

export interface Brand {
  imgUrl: string;
  name: string;
  website: string;
  rank: number;
}
const Testimonial = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [testimonials, setTestimonials] = useState<Testimonials[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTouchStart = () => {
    setIsHovered(true);
  };

  const handleTouchEnd = () => {
    setIsHovered(false);
  };

  const handleClick = (index: number) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const query = '*[_type=="testimonials"] | order(rank desc)';
    const brandsQuery = '*[_type=="brands"] | order(rank desc)';
    client.fetch(query).then((data: Testimonials[]) => {
      const sortedTestimonials = data.sort(
        (a: Testimonials, b: Testimonials) => b.rank - a.rank
      );
      setTestimonials(sortedTestimonials);
    });
    client.fetch(brandsQuery).then((data: Brand[]) => {
      const sortedBrands = data.sort((a: Brand, b: Brand) => b.rank - a.rank);
      setBrands(sortedBrands);
    });
  }, []);
  useEffect(() => {
    const changeTestimonial = () => {
      if (!isHovered) {
        const newIndex = (currentIndex + 1) % testimonials.length;
        setCurrentIndex(newIndex);
      }
    };

    const interval = setInterval(changeTestimonial, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, testimonials.length, isHovered]);

  const test = testimonials[currentIndex];

  return (
    <>
      <h2 className="head-text" style={{ marginTop: 15 }}>
        Testimonials
      </h2>
      {testimonials.length && (
        <>
          <div
            className="app__testimonial-item app__flex"
            style={{ marginTop: 15 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <img src={urlFor(test.imgUrl).url()} alt="testimonial" />
            <div className="app__testimonial-content">
              <p className="p-text">{test.feedback}</p>
              <div>
                <h4 className="bold-text">{test.name}</h4>
                <h5 className="p-text">{test.company}</h5>
              </div>
            </div>
          </div>

          <div className="app__testimonial-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}
      <div className="app__testimonial-brands app__flex">
        {brands.map((brand, index) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand.name + index}
          >
            <a href={brand.website} target="_blank" rel="noopener noreferrer">
              <img src={urlFor(brand.imgUrl).url()} alt={brand.name} />
            </a>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "testimonials",
  "app__whitebg"
);
