import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Achievement.scss";
import { format } from "date-fns";

export interface Achievements {
  title: string;
  link: string;
  date: string;
}
export interface WorkDetail {
  desc: string;
}
export interface extraDocument {
  certificate: string;
}
export interface Volunteer {
  name: string;
  bgColor: string;
  logo: string;
  website: string;
  position: string;
  workType: string;
  location: string;
  startDate: string;
  endDate: string;
  description: WorkDetail[];
  certificate: extraDocument[];
}
const Achievement = () => {
  const [achievement, setAchievement] = useState<Achievements[]>([]);
  const [volunteer, setVolunteer] = useState<Volunteer[]>([]);
  const [showAllVolunteer, setShowAllVolunteer] = useState(false);
  const [showAllAchievements, setShowAllAchievements] =
    useState<boolean>(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState<boolean[]>(
    []
  );

  const volunteerToShowInitially = 2;
  const maxDescriptionWords = 50;
  const achievementsToShowInitially = 10;

  useEffect(() => {
    const query = '*[_type=="achievement"] | order(date desc)';
    const volunteerQuery = '*[_type=="volunteer"] | order(startDate desc)';
    client.fetch(query).then((data: Achievements[]) => {
      const sortedAchievements = data.sort(
        (a: Achievements, b: Achievements) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setAchievement(sortedAchievements);
    });
    client.fetch(volunteerQuery).then((data: Volunteer[]) => {
      const sortedVolunteer = data.sort(
        (a: Volunteer, b: Volunteer) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      );
      setVolunteer(sortedVolunteer);
    });
  }, []);

  const toggleAchievements = () => {
    setShowAllAchievements(!showAllAchievements);
  };
  const toggleVolunteer = () => {
    setShowAllVolunteer(!showAllVolunteer);
  };
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "MMMM yyyy");
  };
  const computeDuration = (startDate: string, endDate: string): string => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();

    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30.44));

    const years = Math.floor(diffMonths / 12);
    const months = diffMonths % 12;

    if (years > 0 && months > 0) {
      return `${years} yr ${months} mos`;
    } else if (years > 0) {
      return `${years} yr`;
    } else {
      return `${months} mos`;
    }
  };
  const parseText = (text: string) => {
    const boldRegex = /\*(.*?)\*/g;
    const boldText = text.replace(boldRegex, "<strong>$1</strong>");
    return <span dangerouslySetInnerHTML={{ __html: boldText }} />;
  };
  const totalWordsInDescription = volunteer.reduce((total, exp) => {
    return (
      total +
      (exp.description?.reduce((count, work) => {
        return count + work.desc.split(" ").length;
      }, 0) || 0)
    );
  }, 0);
  const toggleDescription = (index: number) => {
    const updatedExpandedDescriptions = [...expandedDescriptions];
    updatedExpandedDescriptions[index] = !updatedExpandedDescriptions[index];
    setExpandedDescriptions(updatedExpandedDescriptions);
  };
  return (
    <>
      <h2 className="head-text" style={{ marginTop: 15 }}>
        Achievements & Volunteer Experiences
      </h2>
      <div className="app__achievement-container">
        <motion.div className="app__achievement-list">
          {showAllAchievements
            ? achievement.map((achievement, index) => (
                <motion.div className="achievement-item" key={index}>
                  <a
                    href={achievement.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`achievement-link ${
                      achievement.link ? "with-link" : ""
                    }`}
                  >
                    {achievement.title}
                  </a>
                </motion.div>
              ))
            : achievement
                .slice(0, achievementsToShowInitially)
                .map((achievement, index) => (
                  <motion.div className="achievement-item" key={index}>
                    <a
                      href={achievement.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`achievement-link ${
                        achievement.link ? "with-link" : ""
                      }`}
                    >
                      {achievement.title}
                    </a>
                  </motion.div>
                ))}
          {achievement.length > achievementsToShowInitially && (
            <p className="read-more-text" onClick={toggleAchievements}>
              {showAllAchievements ? "Show Less" : "Read More..."}
            </p>
          )}
        </motion.div>

        <motion.div className="app__achievement-exp">
          {volunteer.length > 0 &&
            volunteer
              .slice(
                0,
                showAllVolunteer ? volunteer.length : volunteerToShowInitially
              )
              .map((exp, index) => (
                <motion.div className="app__achievement-exp-item" key={index}>
                  <div className="app__achievement-exp-year">
                    <a href={exp.website} target="_blank" rel="noreferrer">
                      <img
                        src={urlFor(exp.logo).url()}
                        alt={`Logo of ${exp.name}`}
                        className="circular-image"
                      />
                    </a>
                  </div>

                  <motion.div className="app__achievement-exp-works">
                    <h3 className="bold-text">{exp.position}</h3>
                    <div className="company-info">
                      <p className="p-text">{exp.name}</p>
                      {exp.workType && (
                        <span className="separator-dot">. </span>
                      )}
                      {exp.workType && <p className="p-text">{exp.workType}</p>}
                    </div>

                    <div className="company-info">
                      {exp.startDate && (
                        <p className="p-time">
                          {formatDate(exp.startDate)} -{" "}
                          {exp.endDate ? formatDate(exp.endDate) : "Present"}
                        </p>
                      )}
                      {(exp.startDate || exp.endDate) && (
                        <span className="separator-dot">. </span>
                      )}
                      {exp.startDate && (
                        <p className="p-time">
                          {computeDuration(exp.startDate, exp.endDate)}
                        </p>
                      )}
                    </div>
                    <p className="p-time">{exp.location}</p>

                    <div>
                      <div>
                        <div>
                          {exp.description &&
                          exp.description.length > 0 &&
                          totalWordsInDescription > maxDescriptionWords &&
                          !expandedDescriptions[index] ? (
                            <>
                              {exp.description &&
                                exp.description.map(
                                  (work, idx) =>
                                    idx < 2 && (
                                      <h5 className="custom-h5" key={idx}>
                                        {parseText(work.desc)}
                                      </h5>
                                    )
                                )}
                              <div className="certificates-container">
                                {exp.certificate &&
                                  exp.certificate.length > 0 &&
                                  exp.certificate.map((cert, index) => (
                                    <a
                                      key={index}
                                      href={urlFor(cert.certificate).url()}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div className="image-container">
                                        <img
                                          src={urlFor(cert.certificate).url()}
                                          alt={`Certificate ${index}`}
                                        />
                                      </div>
                                    </a>
                                  ))}
                              </div>
                              <p
                                className="read-more-text"
                                onClick={() => toggleDescription(index)}
                              >
                                Read More...
                              </p>
                            </>
                          ) : (
                            <>
                              {exp.description &&
                                exp.description.map((work, index) => (
                                  <h5 className="custom-h5" key={index}>
                                    {parseText(work.desc)}
                                  </h5>
                                ))}
                              <div className="certificates-container">
                                {exp.certificate &&
                                  exp.certificate.length > 0 &&
                                  exp.certificate.map((cert, index) => (
                                    <a
                                      key={index}
                                      href={urlFor(cert.certificate).url()}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div className="image-container">
                                        <img
                                          src={urlFor(cert.certificate).url()}
                                          alt={`Certificate ${index}`}
                                        />
                                      </div>
                                    </a>
                                  ))}
                              </div>
                              {exp.description &&
                                exp.description.length > 2 && (
                                  <p
                                    className="read-more-text"
                                    onClick={() => toggleDescription(index)}
                                  >
                                    Show Less...
                                  </p>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              ))}

          {!showAllVolunteer && volunteer.length > volunteerToShowInitially && (
            <p className="read-more-text" onClick={toggleVolunteer}>
              Read More...
            </p>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Achievement, "app__achievement"),
  "achievement",
  "app__primarybg"
);
